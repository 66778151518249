var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",staticClass:"alert-modal",attrs:{"centered":"","size":"lg","hide-header-close":"","content-class":"modal-register","footer-class":"pt-0 border-0"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100 align-items-center"},[_c('div',{staticClass:"modal-title"},[_vm._v("ที่อยู่ใบกำกับภาษี")])])]},proxy:true},{key:"modal-footer",fn:function({ hide }){return [_c('div',{staticClass:"d-flex justify-content-between w-100 my-0"},[_c('b-button',{staticClass:"btn-modal btn-hover w-50 mr-1",attrs:{"variant":"close","disabled":_vm.isLoading},on:{"click":function($event){return hide('forget')}}},[_vm._v(" ยกเลิก ")]),_c('b-button',{staticClass:"btn-modal btn-hover w-50 ml-1",attrs:{"variant":"primary-color","disabled":_vm.isSelectBilling == false || _vm.isLoading},on:{"click":_vm.printBilling}},[_vm._v(" ยืนยัน ")])],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"panel-invoice"},[_vm._l((_vm.billingList),function(items){return [_c('div',{key:'list-billing' + items.id,class:[
          'container-invoice',
          (items.is_default == 1 && _vm.isSelectBilling == 0) ||
          _vm.isSelectBilling == items.id
            ? 'is-default'
            : '',
        ],on:{"click":function($event){return _vm.selectUserToPrint(items.id, items)}}},[_c('div',{staticClass:"color-primary"},[_vm._v(" "+_vm._s(items.billing_address_type == 0 ? "บุคคลธรรมดา" : "นิติบุคคล")+" ")]),_c('div',{staticClass:"text-bold text-black"},[_vm._v(" "+_vm._s(items.first_name)+" "),(items.billing_address_type == 0)?_c('span',{staticClass:"text-black text-bold"},[_vm._v(" "+_vm._s(items.last_name)+" ")]):_vm._e(),_c('span',{staticClass:"text-gray-1"},[_vm._v(" "+_vm._s(_vm.convertTelePhone(items.telephone))+" ")])]),_c('p',{staticClass:"text-gray-1 mb-0 f-md"},[_c('span',{staticClass:"text-gray-1",domProps:{"innerHTML":_vm._s(_vm.displayAddress(items))}})]),_c('div',{staticClass:"panel-action"},[(items.is_default == 1)?_c('div',{staticClass:"box-default"},[_vm._v("Default")]):_vm._e(),_c('div',[_c('font-awesome-icon',{staticClass:"pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){return _vm.addBilling(items.id)}}})],1)])])]}),(_vm.billingList.length < _vm.limit || _vm.limit == 0)?_c('div',{staticClass:"container-invoice add",on:{"click":_vm.addInvoiceAddress}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"flex":"1"}},[_c('b-button',{attrs:{"variant":"primary-color"},on:{"click":_vm.addBilling}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'plus']}}),_c('span',{attrs:{"data-lang":"add_billing"}},[_vm._v("เพิ่ม ")]),_vm._v(" "+_vm._s(_vm.limit > 0 ? `(${_vm.billingList.length}/${_vm.limit})` : "")+" ")],1)],1)]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }