<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
      scrollable
    >
      <template #modal-header>
        <div class="modal-title">คะแนนสะสม</div>
      </template>
      <div class="d-block">
        <div class="d-flex justify-content-between w-100 title-point">
          <label>คะแนนรวม</label>
          <label><b>{{ totalPoint | numeral("0,0")}} </b> คะแนน</label>
        </div>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          sticky-header
          empty-text="No matching records found"
          class="table-main mb-0"
          :no-border-collapse="false"
        >
          <template v-slot:cell(branch_point)="data">
            <div>{{ data.item.branch_point | numeral("0,0") }}</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <template #modal-footer="{ hide }">
        <div class="text-center w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      showModal: false,
      fields: [
        {
          key: "branch_name",
          label: "สาขา",
          thStyle: { width: "65%" },
          tdClass: "text-left",
        },
        {
          key: "branch_point",
          label: "คะแนน",
          thStyle: { width: "35%" },
        },
      ],
      items: [],
      member: {
        id: 0,
        name: "",
        userGuid: "",
        branchPoint: 0,
        centerPoint: 0,
      },
      memberRaw: {},
      totalPoint: 0
    };
  },

  methods: {
    async show(item) {
      this.memberRaw = item;
      this.member.id = item.member_id;
      this.member.name = item.name;
      this.member.userGuid = item.user_guid;
      this.member.branchPoint = item.branch_point;
      this.member.centerPoint = item.center_point;
      
      await this.getUserBranchPoint(this.member.userGuid);

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getUserBranchPoint(userGuid) {
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrl}/customer/get_branchpoint_byid/${userGuid}`)
        .then((data) => {
          if (data.result == 1) {
            this.items = data.detail.listBranchPoint;
            this.totalPoint = data.detail.total_point;
          }
        });
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  font-size: 16px;
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .b-table-sticky-header {
  max-height: calc(65vh - 46px);
}
.title-point {
  color: #333;
  font-size: 16px;
}
</style>
