<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">รายละเอียดบิล</div>
      </template>
      <div class="py-3">
        <b-row class="mx-0 text-center header-products">
          <b-col cols="1" class="header-products-item">
            <p>จำนวน</p>
            <p>Qty</p>
          </b-col>
          <b-col md="5" class="header-products-item">
            <p>รายละเอียดสินค้า/โปรโมชั่น</p>
            <p>Description/Promotion</p>
          </b-col>
          <b-col class="header-products-item">
            <p>ราคาต่อหน่วย</p>
            <p>Unit Price</p>
          </b-col>
          <b-col class="header-products-item">
            <p>ส่วนลด</p>
            <p>Discount</p>
          </b-col>
          <b-col class="header-products-item">
            <p>ราคาสุทธิ</p>
            <p>Net</p>
          </b-col>
          <b-col class="header-products-item">
            <p>รายละเอียด</p>
          </b-col>
        </b-row>
      </div>
      <div class="product-list-container" v-if="transaction">
        <div class="item-products" v-if="transaction.product.length > 0">
          <b-row
            v-for="(product, index) in transaction.product"
            :key="index"
            class="product-panel mx-0 mb-2 h-100"
          >
            <b-col
              cols="1"
              class="border-product d-flex justify-content-center align-items-center"
            >
              <p class="my-0">{{ product.quantity }}</p>
            </b-col>
            <b-col md="5" class="pointer py-1">
              <p>{{ product.barcode }}</p>
              <p class="product-info">{{ product.name }}</p>
              <p v-if="product.promotion">
                <span> โปรโมชั่น: </span>
                <span
                  v-for="(promotion, index2) in product.promotion"
                  :key="index2"
                >
                  {{ promotion.name }} ลด {{ promotion.discount_value }},
                </span>
              </p>
            </b-col>
            <b-col
              class="price d-flex justify-content-center align-items-center"
            >
              <div>
                <p v-if="product.sap_price == 0">
                  {{ product.price | numeral("0,0.00") }}
                </p>
                <p v-else>
                  {{ product.sap_price | numeral("0,0.00") }}
                </p>
                <p>
                  {{ product.sap_price | numeral("0,0.00") }}
                </p>
                <p
                  v-if="product.sap_price != product.price"
                  class="normal-unit-price"
                >
                  {{ product.price | numeral("0,0.00") }}
                </p>
              </div>
            </b-col>
            <b-col
              class="price d-flex justify-content-center align-items-center"
            >
              <p>
                {{ product.total_discount | numeral("0,0.00") }}
              </p>
            </b-col>
            <b-col
              class="price d-flex justify-content-center align-items-center"
            >
              <p>
                {{ product.after_discount_price | numeral("0,0.00") }}
              </p>
            </b-col>
            <b-col
              class="price d-flex justify-content-center align-items-center"
            >
              <InputText
                v-model="form.productList[index].group_barcode"
                className="mb-2"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-center my-0 w-100">
          <div class="d-flex w-50">
            <b-button
              variant="primary-color"
              class="btn-modal btn-hover w-50 mr-1"
              @click="updateGroupBarcode"
              :disabled="isLoading"
            >
              ยืนยัน
            </b-button>
            <b-button
              variant="close"
              class="btn-modal btn-hover w-50 ml-1"
              @click="hide('forget')"
              :disabled="isLoading"
            >
              ยกเลิก
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        transactionId: 0,
        productList: [],
      },
      transaction: null,
    };
  },
  methods: {
    async show(transaction) {
      this.transaction = { ...transaction };
      this.form = {
        transactionId: this.transaction.id,
        productList: [],
      };
      await this.transaction.product.forEach((product) => {
        this.form.productList.push({
          group_barcode: product.group_barcode || "",
          product_id: product.product_id,
        });
      });
      this.transaction;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async updateGroupBarcode() {
      this.isLoading = true;
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .put(
          `${this.$baseUrl}/transaction/InsertGroupBarcodeTransaction/${this.form.transactionId}`,
          this.form
        )
        .then((data) => {
          this.isLoading = false;
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert(data.message);
            this.$emit("success", this.transaction.id);
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.panel-display-count {
  padding-top: 10px;
  p.label {
    color: #333 !important;
    font-weight: bold !important;
  }
  p {
    color: #999 !important;
    font-size: 14px !important;
  }
}
.header-products {
  font-size: 14px;
  font-weight: bold;
  .header-products-item {
    padding: 0px;
  }
}
.product-list-container {
  overflow: auto;
  height: calc(100% - 46px - 89px - 87px - 40px);
  position: relative;
  .wrap-no-product {
    width: 100%;
    height: 100%;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    .no-product {
      text-align: center;
      font-size: 20px;
      padding: 15px;
      background-color: #f7f7f7;
      border-radius: 5px;
      color: #dcdcdc;
      width: 450px;
    }
  }
}
.product-panel {
  background-color: rgb(244, 244, 244) !important;
}
.product-panel.active {
  background-color: var(--secondary-color) !important;
}
.item-products {
  color: #707070;
  font-size: 16px;
  border-radius: 8px;
  font-family: Kanit-Medium !important;
  svg {
    color: #000;
  }
  .price {
    font-size: 14px;
    text-align: center;
  }
  .product-info {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
    color: #707070 !important;
  }
  .normal-unit-price {
    text-decoration: line-through;
  }
}
</style>
