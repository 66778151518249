<template>
  <div>
    <div class="my-3">
      <b-tabs v-model="tabIndex">
        <b-tab title="ข้อมูลใบกำกับภาษี">
          <b-row>
            <b-col cols="12" md="6" class="label-header">
              {{ getName(1, "name") }}
            </b-col>
            <b-col cols="12" class="mb-2">
              <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                <b-form-radio-group
                  id="billing_address_type"
                  v-model="form.billing_address_type"
                  :options="[
                    { value: '0', text: 'บุคคลธรรมดา' },
                    { value: '1', text: 'นิติบุคคล' },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  @change="handleChangeType"
                  name="billing_address_type-options"
                >
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" md="6" class="mb-2">
              <template v-if="getName(2, 'is_show')">
                <InputText
                  :textFloat="getName(2, 'name')"
                  :placeholder="getName(2, 'name')"
                  type="number"
                  name="tax-id"
                  class="mb-0"
                  :isRequired="getName(1, 'required')"
                  v-model="form.tax_id"
                  :v="$v.form.tax_id"
                  :isValidate="$v.form.tax_id.$error"
              /></template>
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" md="6">
              <template v-if="getName(3, 'is_show')">
                <InputSelect
                  :title="getName(3, 'name')"
                  name="prefix-loading"
                  :options="[]"
                  value=""
                  :disabled="true"
                  v-if="isPrefixLoading"
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >Loading...
                    </b-form-select-option>
                  </template>
                </InputSelect>
                <InputSelect
                  v-else
                  :title="getName(3, 'name')"
                  name="prefix-title"
                  :options="prefixOptions"
                  v-model="form.prefix"
                  valueField="name"
                  textField="name"
                  @onDataChange="form.prefix = $event"
                  :isRequired="getName(3, 'required')"
                >
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- กรุณาเลือกข้อมูล --
                    </b-form-select-option>
                  </template>
                </InputSelect>
              </template>
            </b-col>

            <b-col cols="12" md="6" class="mb-2" v-if="checkOtherValue()">
              <InputText
                :textFloat="getName(3, 'name') + ' อื่นๆ'"
                :placeholder="
                  form.billing_address_type == 1 ? 'คำนำหน้าธุรกิจ' : 'คำนำหน้า'
                "
                type="text"
                name="tax-id"
                v-model="form.prefix_other"
              />
            </b-col>

            <div class="break-normal"></div>
            <b-col cols="12" md="6" class="mb-2">
              <template v-if="getName(4, 'is_show')">
                <InputText
                  :textFloat="getName(4, 'name')"
                  :placeholder="getName(4, 'name')"
                  type="text"
                  name="business-name"
                  :isRequired="getName(4, 'required')"
                  v-model="form.first_name"
                  :v="$v.form.first_name"
                  :textDesc="
                    form.billing_address_type == 1 &&
                    'ใส่เฉพาะธุรกิจ(บริษัทไทยแลนด์จำกัด = ไทยแลนด์)'
                  "
                  :isValidate="$v.form.first_name.$error"
                >
                </InputText>
              </template>
            </b-col>
            <div
              class="break-normal"
              v-if="form.billing_address_type == 1"
            ></div>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
              v-if="form.billing_address_type == 1"
            >
              <template v-if="getName(21, 'is_show')">
                <label class="title">{{ getName(21, "name") }}</label>
                <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                  <b-form-radio-group
                    id="branch-type"
                    v-model="form.branch_type"
                    :options="[
                      { value: 'สำนักงานใหญ่', text: 'สำนักงานใหญ่' },
                      { value: 'สาขา', text: 'สาขา' },
                    ]"
                    :aria-describedby="ariaDescribedby"
                    name="branch-type"
                    @change="changeBranchType"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </template>
            </b-col>
            <b-col
              cols="12"
              md="6"
              v-if="
                form.billing_address_type == 1 && form.branch_type == 'สาขา'
              "
            >
              <div class="d-flex" style="column-gap: 15px">
                <InputText
                  textFloat="รหัสสาขา"
                  placeholder="รหัสสาขา"
                  type="text"
                  name="branch_number"
                  style="flex: 1"
                  isRequired
                  v-model="form.company_branch_no"
                  :v="$v.form.company_branch_no"
                  :isValidate="$v.form.company_branch_no.$error"
                />
                <InputText
                  textFloat="ชื่อสาขา"
                  placeholder="ชื่อสาขา"
                  type="text"
                  name="branch_name"
                  style="flex: 1"
                  isRequired
                  v-model="form.company_branch"
                  :v="$v.form.company_branch"
                  :isValidate="$v.form.company_branch.$error"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
              v-if="form.billing_address_type == '0'"
            >
              <template v-if="getName(5, 'is_show')">
                <InputText
                  :textFloat="getName(5, 'name')"
                  :placeholder="getName(5, 'name')"
                  type="text"
                  name="surname"
                  :isRequired="getName(5, 'required')"
                  v-model="form.last_name"
                  :v="$v.form.last_name"
                  :isValidate="$v.form.last_name.$error"
                />
              </template>
            </b-col>
            <div
              class="break-normal"
              v-if="form.billing_address_type == 1"
            ></div>

            <b-col cols="12" md="6" class="mb-2">
              <template v-if="getName(6, 'is_show')">
                <InputText
                  :textFloat="getName(6, 'name')"
                  :placeholder="getName(6, 'name')"
                  :isRequired="getName(6, 'required')"
                  type="email"
                  name="email"
                  v-model="form.email"
                  :v="$v.form.email"
                  :isValidate="$v.form.email.$error"
                />
              </template>
            </b-col>
            <b-col cols="12" md="6" class="mb-2">
              <div
                class="d-flex"
                style="column-gap: 15px"
                v-if="getName(7, 'is_show')"
              >
                <InputText
                  type="number"
                  :textFloat="getName(7, 'name')"
                  :isRequired="getName(7, 'required')"
                  v-model="form.telephone"
                  :placeholder="getName(7, 'name')"
                  :v="$v.form.telephone"
                  :isValidate="$v.form.telephone.$error"
                  :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10);'"
                >
                </InputText>

                <InputText
                  type="number"
                  textFloat=" "
                  placeholder="เบอร์ต่อ"
                  v-model="form.ext"
                  :disabled="!form.telephone"
                ></InputText>
              </div> </b-col
          ></b-row>
        </b-tab>
        <b-tab title="ข้อมูลที่อยู่ใบกำกับภาษี">
          <b-row>
            <b-col cols="12" md="6">
              <InputSelect
                :title="getName(8, 'name')"
                name="name-title"
                :options="Country"
                v-model="form.country"
                valueField="englishName"
                textField="englishName"
                :isRequired="getName(8, 'required')"
                :v="$v.form.country"
                :isValidate="$v.form.country.$error"
                @onDataChange="changeCountry"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >-- กรุณาเลือกข้อมูล --
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <div class="break-normal"></div>
            <!-- <template v-if="form.country == 'ประเทศไทย' || form.country == ''"> -->
            <b-col cols="12" md="6" class="mb-2">
              <InputText
                :textFloat="getName(9, 'name')"
                :placeholder="getName(9, 'name')"
                type="text"
                :isRequired="getName(9, 'required')"
                name="address"
                v-model="form.address"
                :v="$v.form.address"
                :isValidate="$v.form.address.$error"
              />
            </b-col>
            <b-col cols="12" md="6" class="mb-2">
              <InputText
                :textFloat="getName(10, 'name')"
                :placeholder="getName(10, 'name')"
                :isRequired="getName(10, 'required')"
                type="text"
                name="building"
                v-model="form.building_name"
              />
            </b-col>
            <b-col cols="12" md="6" class="mb-2">
              <InputText
                :textFloat="getName(11, 'name')"
                :placeholder="getName(11, 'name')"
                :isRequired="getName(11, 'required')"
                type="text"
                name="alley"
                v-model="form.alley"
              />
            </b-col>

            <b-col cols="12" md="6" class="mb-2">
              <InputText
                :textFloat="getName(12, 'name')"
                :placeholder="getName(12, 'name')"
                :isRequired="getName(12, 'required')"
                type="text"
                name="road"
                v-model="form.road"
              />
            </b-col>
            <b-col cols="12" md="6">
              <template
                v-if="form.country == 'ประเทศไทย' || form.country == ''"
              >
                <InputSelect
                  :title="getName(13, 'name')"
                  name="province"
                  :options="options.province.field_choices"
                  v-model="form.province"
                  valueField="name"
                  textField="name"
                  :isRequired="getName(13, 'required')"
                  :v="$v.form.province"
                  :isValidate="$v.form.province.$error"
                  @onDataChange="
                    (form.province = $event),
                      handleChange(
                        $event,
                        options.province.field_choices,
                        'district',
                        'sub_district'
                      )
                  "
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- กรุณาเลือกข้อมูล --
                    </b-form-select-option>
                  </template>
                </InputSelect>
              </template>
              <template v-else>
                <InputText
                  :textFloat="getName(13, 'name')"
                  :placeholder="getName(13, 'name')"
                  type="text"
                  name="province"
                  v-model="form.province"
                  :v="$v.form.province"
                  :isValidate="$v.form.province.$error"
                  :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
                />
              </template>
            </b-col>
            <b-col cols="12" md="6" class="mb-2">
              <template v-if="form.country == 'ประเทศไทย'">
                <InputSelect
                  :title="getName(14, 'name')"
                  name="District"
                  :options="[]"
                  value=""
                  :disabled="true"
                  v-if="loading.district"
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >Loading...
                    </b-form-select-option>
                  </template>
                </InputSelect>
                <InputSelect
                  v-else
                  :title="getName(14, 'name')"
                  name="District"
                  :options="options.district.field_choices || []"
                  v-model="form.district"
                  valueField="name"
                  textField="name"
                  :isRequired="getName(14, 'required')"
                  :v="$v.form.district"
                  :isValidate="$v.form.district.$error"
                  :disabled="!form.province"
                  @onDataChange="
                    (form.district = $event),
                      handleChange(
                        $event,
                        options.district.field_choices,
                        'subdistrict',
                        'sub_district'
                      )
                  "
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- กรุณาเลือกข้อมูล --
                    </b-form-select-option>
                  </template>
                </InputSelect></template
              >
              <template v-else>
                <InputText
                  :textFloat="getName(14, 'name')"
                  :placeholder="getName(14, 'name')"
                  type="text"
                  name="District"
                  v-model="form.district"
                  :v="$v.form.district"
                  :isValidate="$v.form.district.$error"
                  :oninput="'javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);'"
                />
              </template>
            </b-col>
            <b-col cols="12" md="6">
              <template v-if="form.country == 'ประเทศไทย'">
                <InputSelect
                  :title="getName(15, 'name')"
                  name="Subdistrict"
                  :options="[]"
                  value=""
                  :disabled="true"
                  v-if="loading.subdistrict"
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >Loading...
                    </b-form-select-option>
                  </template>
                </InputSelect>
                <InputSelect
                  v-else
                  :title="getName(15, 'name')"
                  name="subdistrict"
                  :options="options.subdistrict.field_choices || []"
                  v-model="form.sub_district"
                  :v="$v.form.sub_district"
                  :isValidate="$v.form.sub_district.$error"
                  valueField="name"
                  textField="name"
                  :isRequired="getName(15, 'required')"
                  @onDataChange="
                    (form.sub_district = $event),
                      handleChange(
                        $event,
                        options.subdistrict.field_choices,
                        'zip_code'
                      )
                  "
                  :disabled="!form.district"
                >
                  <!-- isRequired -->
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- กรุณาเลือกข้อมูล --
                    </b-form-select-option>
                  </template>
                </InputSelect></template
              >
              <template v-else>
                <InputText
                  :textFloat="getName(15, 'name')"
                  :placeholder="getName(15, 'name')"
                  type="text"
                  name="Subdistrict"
                  v-model="form.sub_district"
                  :v="$v.form.sub_district"
                  :isValidate="$v.form.sub_district.$error"
                  :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
                />
              </template>
            </b-col>

            <b-col cols="12" md="6" class="mb-2">
              <InputText
                :textFloat="getName(16, 'name')"
                :placeholder="getName(16, 'name')"
                type="number"
                name="zip_code"
                v-model="form.zip_code"
                :v="$v.form.zip_code"
                :isValidate="$v.form.zip_code.$error"
                :isRequired="form.country == 'ประเทศไทย'"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <!-- </template> -->
      <!-- <b-col cols="12"> -->
      <div class="content-between mt-3">
        <b-form-checkbox
          id="is_default"
          name="is_default"
          :value="1"
          :unchecked-value="0"
          v-model="form.is_default"
        >
          Default
        </b-form-checkbox>
        <b-button variant="custom-link" @click="deleteAddress" v-if="form.id"
          >Delete</b-button
        >
      </div>
      <!-- </b-col> -->
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import InputSelect from "@/components/inputs/InputSelect";
import {
  required,
  minLength,
  maxLength,
  requiredIf,
  email,
  numeric,
} from "vuelidate/lib/validators";
import Country from "@/lang/country.json";
import {
  province,
  billingField,
  mapBillingField,
} from "@/assets/json/fieldAddress.js";

export default {
  props: {
    addressOptions: {
      required: false,
      type: Object,
    },

    getField: {
      required: false,
    },
    id: {
      required: false,
    },
  },
  components: {
    InputSelect,
    InputRadioGroup,
    InputText,
  },
  validations() {
    for (const item of this.FieldList) {
      const key = this.checkIsProfile(item.field_billing_type_id);

      if (key) {
        if (item.id == 2)
          billingField[key] = {
            required: requiredIf(() => item.required),
            numeric,
            minLength: minLength(13),
            maxLength: maxLength(13),
          };
        else if (item.id == 7)
          billingField[key] = {
            required: requiredIf(() => item.required),
            minLength: minLength(9),
          };
        else if (item.id == 6)
          billingField[key] = {
            required: requiredIf(() => item.required),
            email,
          };
        else if (item.id == 5)
          billingField[key] = {
            required: requiredIf(
              () => this.form.billing_address_type == 0 && item.required
            ),
          };
        else if ([9, 10, 11, 12, 13, 14, 15, 16].includes(item.id))
          billingField[key] = {
            required: requiredIf(
              () => this.form.country == "ประเทศไทย" && item.required
            ),
          };
        else billingField[key] = { required: requiredIf(() => item.required) };
      }
    }

    return {
      form: {
        ...billingField,
        company_branch: {
          required: requiredIf(() => {
            return (
              this.form.branch_type == "สาขา" &&
              this.form.billing_address_type == 1
            );
          }),
        },
        company_branch_no: {
          required: requiredIf(() => {
            return (
              this.form.branch_type == "สาขา" &&
              this.form.billing_address_type == 1
            );
          }),
        },
        province: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        sub_district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        zip_code: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
      },
    };
  },
  data() {
    return {
      form: {
        user_guid: "",
        billing_address_type: 0,
        tax_id: "",
        prefix: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        telephone_country_id: 1,
        country: "ประเทศไทย",
        address: "",
        branch_type: "สำนักงานใหญ่",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0,
      },
      list: [],

      optionalTelephone: [],
      options: {
        province: { field_choices: province },
        district: { field_choices: [] },
        subdistrict: { field_choices: [] },
      },

      loading: {
        district: false,
        subdistrict: false,
      },
      FieldList: [],
      companyFields: [],
      isPrefixLoading: false,
      Country: Country,
      tabIndex: 0,
    };
  },
  watch: {
    addressOptions(val) {
      this.options = { ...val };
    },
  },
  created() {
    if (this.id) this.getById(this.id);
    else this.getFields();
  },
  computed: {
    prefixOptions() {
      if (this.form.billing_address_type == 1)
        return this.companyField.field_choices;
      let field = this.FieldList.find((el) => el.field_profile_type_id == 3);
      return field ? field.field_choices : [];
    },
  },
  methods: {
    async show() {
      if (this.id) await this.getById(this.id);
      else await this.getFields();
      await this.$store.dispatch("setMainLoading", false);
    },
    async getById(id) {
      const result = await this.$axios(
        `${this.$baseUrl}/customer/GetBillingInformationById/${id}`
      );

      await this.getFields(result.detail.data.billing_address_type);
      let data = JSON.parse(JSON.stringify(result.detail.data));
      //
      await this.$nextTick();
      this.form = result.detail.data;
      if (
        !this.prefixOptions.find((el) => el.name == this.form.prefix) &&
        this.form.prefix
      ) {
        this.form.prefix_other = data.prefix;
        this.form.prefix = this.prefixOptions.find(
          (el) => el.choice_type_id == 3
        ).name;
      }
      if (this.form.telephone) {
        if (this.form.telephone.includes("-")) {
          let phone = this.form.telephone.split("-");
          this.form.telephone = phone[0];
          this.form.ext = phone[1] || "";
        }
      }
      if (data.country == "ประเทศไทย") {
        if (data.province) {
          await this.handleChange(
            data.province,
            this.options.province.field_choices,
            "district",
            "subdistrict",
            false
          );
        }
        if (data.district) {
          await this.handleChange(
            data.district,
            this.options.district.field_choices,
            "subdistrict",
            "sub_district",
            false
          );
        }
      }
    },
    editAddress(id) {
      this.getById(id);
    },
    async submit() {
      this.$v.form.$touch();

      if (this.$v.form.$error) return;
      // this.$bus.$emit("showLoading");
      this.form.user_guid = this.$route.params.userGuid;
      if (this.optionalTelephone)
        this.form.optional_telephone = this.optionalTelephone.join(",");
      let payload = { ...this.form };
      let value = `${payload.telephone}${payload.ext ? "-" + payload.ext : ""}`;
      payload.telephone = value;
      if (this.checkOtherValue()) payload.prefix = this.form.prefix_other;
      var result = null;
      await this.$store.dispatch("setMainLoading", true);
      if (this.form.id) {
        result = await this.axios.put(
          `${this.$baseUrl}/customer/UpdateBillingInformation`,
          payload
        );
      } else {
        result = await this.axios.post(
          `${this.$baseUrl}/customer/CreateBillingInformation`,
          payload
        );
      }
      await this.$store.dispatch("setMainLoading", false);
      // this.$bus.$emit("hideLoading");
      if (result.result) {
        // this.getList();
        this.successAlert();
        this.$emit("success");
        return true;
      } else {
        this.errorAlert(result.message);
        return false;
      }
    },
    async addAddress() {
      this.$bus.$emit("showLoading");
      this.getField().then(async () => await this.getFields());
      this.$refs.sidebar.show();
      this.$bus.$emit("hideLoading");
    },

    async handleChange(val, field, $set, $set2, clear = true) {
      let payload = field.find((el) => el.name == val);
      if (!payload) return;
      if ($set == "zip_code") {
        this.form[$set] = payload ? payload.zip_code : "";
      } else {
        this.loading[$set] = true;
        const result = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${
            payload.id
          }/${$set == "district" ? 1 : 2}`
        );

        this.$nextTick(() => {
          this.options[$set].field_choices = result.detail[$set];

          if (clear) {
            this.form[$set] = "";
            this.form.zip_code = "";
            if ($set2) this.form[$set2] = "";
          }
          this.loading[$set] = false;
        });
      }
    },
    async getList() {
      const result = await this.axios(
        `/customer/GetBillingInformationList/${this.$route.params.id}`
      );

      this.list = result.data.detail.result;
    },
    async deleteAddress() {
      try {
        this.$bus.$emit("showLoading");
        const result = await this.axios.delete(
          `/customer/DeleteBillingInformation/${this.form.id}`
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result) {
          this.successAlert();
          this.$refs.sidebar.hide();
          this.getList();
          // this.back();
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async getFields(type) {
      this.isPrefixLoading = true;
      // await this.$emit("getField");
      await this.$store.dispatch("setMainLoading", true);
      if (this.FieldList.length > 0)
        return (
          await this.$store.dispatch("setMainLoading", false),
          (this.isPrefixLoading = false)
        );
      const result = await this.$axios(
        `${this.$baseUrl}/customer/GetBillingForm`
      );

      await this.$store.dispatch("setMainLoading", false);
      this.FieldList = result.detail.profileField;
      this.companyField = result.detail.companyField;

      this.isPrefixLoading = false;

      return true;
    },
    validateOptional(val) {
      if (val.length == 10) {
        return val;
      }
      return;
    },
    async handleChangeType(val) {
      this.form.prefix = "";
      this.form.last_name = "";
      await this.$nextTick();
      this.$v.$reset();
      this.getFields(val);
    },
    clearField() {
      this.form = {
        user_guid: "",
        billing_address_type: 0,
        telephone_country_id: 1,
        branch_type: "สำนักงานใหญ่",
        tax_id: "",
        prefix: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        country: "ประเทศไทย",
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0,
      };
      this.$v.form.$reset();
    },
    checkOtherValue() {
      let check = this.prefixOptions.find((el) => el.name == this.form.prefix);

      return check ? check.choice_type_id == 3 : false;
    },
    changeCountry(value) {
      this.form.country = value;
      this.form = {
        ...this.form,
        address: "",
        building_name: "",
        alley: "",
        road: "",
        district: "",
        sub_district: "",
        province: "",
        zip_code: "",
      };
    },
    changeBranchType(val) {
      if (val == "สำนักงานใหญ่") {
        this.form.company_branch_no = "";
        this.form.company_branch = "";
      }
    },
    getName(id, key) {
      let name = this.FieldList.find((el) => el.field_profile_type_id == id);
      if (
        this.form.country != "ประเทศไทย" &&
        [9, 10, 11, 12, 13, 14, 15, 16].includes(id) &&
        key == "required"
      )
        return false;

      return name ? name[key] : "";
      // console.log(this.FieldList);
    },
    checkIsProfile(val) {
      const findField = mapBillingField.find((el) => el.id == val);

      return findField ? findField.key : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-address {
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: rgba(0, 0, 21, 0.2);
  border-style: solid;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  &.add {
    border-style: dashed;
    border-color: var(--primary-color);
  }
  .box-default {
    color: green;
    border: 1px solid green;
    border-radius: 0.2rem;
    position: absolute;
    top: 10%;
    right: 10px;
    font-size: 12px;
    padding: 0 5px;
  }
}
.break-normal {
  flex-basis: 100%;
  height: 0;
}
</style>
