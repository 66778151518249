<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">
          โน้ตคุณ {{ member.name }} {{ member.surname }} ({{
            member.telephone
          }})
        </div>
      </template>
      <div class="d-block">
        <InputTextArea
          name="text"
          rows="10"
          v-model="form.note"
          :isValidate="$v.form.note.$error"
          :v="$v.form.note"
          className="mb-0"
        />
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputTextArea,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { note: "" },
      member: {
        id: 0,
        name: "",
        surname: "",
        userGuid: "",
        telephone: "",
      },
    };
  },
  validations() {
    return {
      form: {
        note: {
          required,
        },
      },
    };
  },
  methods: {
    async show(item) {
      this.member.id = item.member_id;
      this.member.name = item.first_name_th;
      this.member.surname = item.last_name_th || '-';
      this.member.userGuid = item.user_guid;
      this.member.telephone = item.telephone;

      this.$v.form.$reset();
      await this.getNote(this.member.userGuid);

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getNote(userGuid) {
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrl}/redeem/get_note/${userGuid}`)
        .then((data) => {
          if (data.result == 1) {
            this.form.note = data.note || "";
          }
        });
      this.isBusy = false;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {
        note: this.form.note,
        user_guid: this.member.userGuid,
        // branch_id: this.branchId,
      };

      await this.axios
        .post(`${this.$baseUrl}/redeem/update_note`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
</style>
