<template>
  <b-modal
    v-model="showModal"
    ref="modal"
    centered
    size="lg"
    class="alert-modal"
    hide-header-close
    content-class="modal-register"
    footer-class="pt-0 border-0"
  >
    <template #modal-header>
      <div class="d-flex justify-content-center w-100 align-items-center">
        <div class="modal-title">ที่อยู่ใบกำกับภาษี</div>
      </div>
    </template>
    <div class="panel-invoice">
      <template v-for="items of billingList">
        <div
          :class="[
            'container-invoice',
            (items.is_default == 1 && isSelectBilling == 0) ||
            isSelectBilling == items.id
              ? 'is-default'
              : '',
          ]"
          :key="'list-billing' + items.id"
          @click="selectUserToPrint(items.id, items)"
        >
          <div class="color-primary">
            <!-- <font-awesome-icon
              icon="check"
              v-if="
                (items.is_default == 1 && isSelectBilling == 0) ||
                isSelectBilling == items.id
              "
              class="text-success"
            ></font-awesome-icon> -->
            {{ items.billing_address_type == 0 ? "บุคคลธรรมดา" : "นิติบุคคล" }}
          </div>
          <div class="text-bold text-black">
            {{ items.first_name }}
            <span
              v-if="items.billing_address_type == 0"
              class="text-black text-bold"
            >
              {{ items.last_name }}
            </span>
            <span class="text-gray-1">
              {{ convertTelePhone(items.telephone) }}
            </span>
          </div>
          <p class="text-gray-1 mb-0 f-md">
            <span v-html="displayAddress(items)" class="text-gray-1"></span>
          </p>
          <div class="panel-action">
            <div class="box-default" v-if="items.is_default == 1">Default</div>
            <div>
              <font-awesome-icon
                icon="pencil-alt"
                class="pointer"
                @click="addBilling(items.id)"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </template>
      <div
        class="container-invoice add"
        @click="addInvoiceAddress"
        v-if="billingList.length < limit || limit == 0"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="flex: 1"
        >
          <b-button variant="primary-color" @click="addBilling">
            <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />
            <span data-lang="add_billing">เพิ่ม </span>
            {{ limit > 0 ? `(${billingList.length}/${limit})` : "" }}
          </b-button>
        </div>
      </div>
    </div>

    <template #modal-footer="{ hide }">
      <div class="d-flex justify-content-between w-100 my-0">
        <b-button
          variant="close"
          class="btn-modal btn-hover w-50 mr-1"
          @click="hide('forget')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
        <b-button
          variant="primary-color"
          class="btn-modal btn-hover w-50 ml-1"
          @click="printBilling"
          :disabled="isSelectBilling == false || isLoading"
        >
          ยืนยัน
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      isLoading: false,
      billingList: [],
      limit: 0,
      isSelectBilling: false,
    };
  },
  methods: {
    async show() {
      await this.getData();
      this.showModal = true;
    },
    async getData() {
      const res = await this.$axios(
        `${this.$baseUrl}/customer/GetBillingInformationList/${this.$route.params.userGuid}`
      );
      this.billingList = res.detail.result;
      let defaultBilling = res.detail.result.find((el) => el.is_default == 1);
      this.isSelectBilling = defaultBilling ? defaultBilling.id : false;
      this.limit = res.detail.limit;
    },
    save() {},

    displayAddress(val) {
      let string = "";
      let isBkk = false;
      if (!val.province) isBkk = false;
      else isBkk = val.province.includes("กรุงเทพ");
      let isTH = val.country == "ประเทศไทย";
      if (val.address) string += val.address + "&nbsp";
      if (val.building_name) string += val.building_name + "&nbsp";
      if (val.alley) string += val.alley + "&nbsp";
      if (val.road) string += `${!isTH ? "" : "ถนน"}${val.road}&nbsp;`;
      if (val.sub_district)
        string += `${!isTH ? "" : isBkk ? "แขวง" : "ตำบล"}${
          val.sub_district
        }&nbsp;`;
      if (val.district)
        string += `${!isTH ? "" : isBkk ? "เขต" : "อำเภอ"}${
          val.district
        }&nbsp;`;
      if (val.province)
        string += `${!isTH ? "" : isBkk ? "" : "จังหวัด"}${val.province}&nbsp;`;
      if (val.zip_code) string += `${val.zip_code}&nbsp;`;
      if (val.country) string += `${val.country}&nbsp;`;
      return string;
    },
    convertTelePhone(val) {
      if (!val) return null;

      if (val.includes("-")) {
        let value = val.split("-");
        return ` ${value[0]} - ${value[1]}`;
      }
      return ` ${val}`;
    },
    addBilling(id) {
      if (id.isTrusted) id = null;
      this.$emit("addBilling", id);
    },
    async selectUserToPrint(id, data) {
      this.isSelectBilling = id;
    },
    async printBilling() {
      try {
        await this.$store.dispatch("setMainLoading", true);
        const res = await this.Axios(
          `${this.$baseUrl}/transaction/GenTransactionFullReciept/${this.$route.params.id}/${this.isSelectBilling}`,
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        );
        var file = new Blob([res.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        await this.$store.dispatch("setMainLoading", false);
      } catch (error) {
        this.errorAlert(error.message);
        await this.$store.dispatch("setMainLoading", false);
      }
    },
    addInvoiceAddress() {},
  },
};
</script>

<style lang="scss">
.container-invoice {
  min-height: 85px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid whitesmoke;
  /* align-items: center; */
  /* justify-content: center; */
  &.add {
    border: 1px dashed var(--primary-color);
    color: var(--primary-color);
  }
  &.is-default {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}
.panel-invoice {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.panel-action {
  position: absolute;
  top: 10%;
  right: 3%;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.box-default {
  color: white;
  background-color: green;
  border: 1px solid green;
  border-radius: 0.3rem;

  font-size: 12px;
  padding: 0 5px;
}
.modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
</style>
